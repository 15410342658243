	/*
  	Flaticon icon font: Flaticon
  	Creation date: 16/05/2020 05:49
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-null:before { content: "\f100"; }
.flaticon-cutlery:before { content: "\f101"; }
.flaticon-event:before { content: "\f102"; }
.flaticon-money:before { content: "\f103"; }
.flaticon-car:before { content: "\f104"; }
.flaticon-medical-book:before { content: "\f105"; }